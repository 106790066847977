<template>
  <div>
    <span class="question">
      1. Which risks of a proposed treatment or care should patients be made aware of?<sup>1</sup>
    </span>
    <span class="guidance">Select all that apply</span>
    <span class="anwser">
      <label>
        <input v-model="a1" type="checkbox">
        Those that are painful
      </label>
    </span>
    <span class="anwser">
      <label>
        <input v-model="b1" type="checkbox">
        Those that occur often
      </label>
    </span>
    <span class="anwser">
      <label>
        <input v-model="c1" type="checkbox">
        Those that the responsible clinician thinks are important
      </label>
    </span>
    <span class="anwser">
      <label>
        <input  v-model="d1" type="checkbox">
        Those that the patient thinks are important
      </label>
    </span>
    <span class="anwser">
      <label>
        <input  v-model="e1" type="checkbox">
        Those that are serious even if very unlikely
      </label>
    </span>
    <span class="notes">
      1. General Medical Council. Openness and honesty when things go wrong: The professional
      duty of candour. Available from: 
      <a href="https://www.gmc-uk.org/ethical-guidance/ethical-guidance-for-doctors/candour---openness-and-honesty-when-things-go-wrong">
        https://www.gmc-uk.org/ethical-guidance/ethical-guidance-for-doctors/candour---openness-and-honesty-when-things-go-wrong
      </a>
      [Last accessed: August 2022]
    </span>
    <hr />
  </div>
</template>

<script>
export default {
  name: 'ModuleOneQuizQ1',
  data() {
    return {
      a1: false,
      b1: false,
      c1: false,
      d1: false,
      e1: false,
    };
  },
  watch: {
    a1(value) { this.$store.commit('answerM1A1', value) },
    b1(value) { this.$store.commit('answerM1B1', value) },
    c1(value) { this.$store.commit('answerM1C1', value) },
    d1(value) { this.$store.commit('answerM1D1', value) },
    e1(value) { this.$store.commit('answerM1E1', value) },
  },
};
</script>
