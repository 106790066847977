<template>
  <div>
    <span class="question">
      4. Which of the following statements is <strong>incorrect</strong>?<sup>1-3</sup>
    </span>
    <span class="anwser">
      <label>
        <input v-model="a4" type="checkbox">
        According to NHS Resolution, apologising is the right thing to do
      </label>
    </span>
    <span class="anwser">
      <label>
        <input v-model="b4" type="checkbox">
        A fitness-to-practice panel may view an apology as evidence of insight
      </label>
    </span>
    <span class="anwser">
      <label>
        <input v-model="c4" type="checkbox">
        Apologising is equivalent to admitting legal liability
      </label>
    </span>
    <span class="notes">
      1. General Medical Council. Openness and honesty when things go wrong. Available from:
      <a href="https://www.gmc-uk.org/ethical-guidance/ethical-guidance-for-doctors/candour---openness-and-honesty-when-things-go-wrong">
        https://www.gmc-uk.org/ethical-guidance/ethical-guidance-for-doctors/candour---openness-and-honesty-when-things-go-wrong
      </a>
      [Last accessed: August 2022];
      2. NHS Resolution. Saying Sorry. Available from:
      <a href="https://resolution.nhs.uk/wp-content/uploads/2018/09/NHS-Resolution-Saying-Sorry.pdf">
        https://resolution.nhs.uk/wp-content/uploads/2018/09/NHS-Resolution-Saying-Sorry.pdf
      </a>
      [Last accessed: August 2022];
      3. General Medical Council. Sanctions guidance for members of medical practitioners tribunals and for the GMC's decision makers. Available from:
      <a href="https://www.mpts-uk.org/-/media/mpts-documents/dc4198-sanctions-guidance---16th-november-2020_pdf-84606971.pdf">
        https://www.mpts-uk.org/-/media/mpts-documents/dc4198-sanctions-guidance---16th-november-2020_pdf-84606971.pdf
      </a>
      [Last accessed: August 2022].
    </span>
    <hr />
  </div>
</template>

<script>
export default {
  name: 'ModuleOneQuizQ4',
  data() {
    return {
      a4: false,
      b4: false,
      c4: false,
    };
  },
  watch: {
    a4(value) { this.$store.commit('answerM1A4', value) },
    b4(value) { this.$store.commit('answerM1B4', value) },
    c4(value) { this.$store.commit('answerM1C4', value) },
  },
};
</script>
