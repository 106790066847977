<template>
  <div>
    <span class="question">
      3. Which of these is not considered to be one of the three steps of an apology?<sup>1</sup>
    </span>
    <span class="anwser">
      <label>
        <input v-model="a3" type="checkbox">
        What happened
      </label>
    </span>
    <span class="anwser">
      <label>
        <input v-model="b3" type="checkbox">
        Who was responsible
      </label>
    </span>
    <span class="anwser">
      <label>
        <input v-model="c3" type="checkbox">
        What can be done to deal with any harm caused
      </label>
    </span>
    <span class="anwser">
      <label>
        <input  v-model="d3" type="checkbox">
        What will be done to prevent someone else being harmed
      </label>
    </span>
    <span class="notes">
      1. General Medical Council. Openness and honesty when things go wrong. Available from:
      <a href="https://www.gmc-uk.org/ethical-guidance/ethical-guidance-for-doctors/candour---openness-and-honesty-when-things-go-wrong">
        https://www.gmc-uk.org/ethical-guidance/ethical-guidance-for-doctors/candour---openness-and-honesty-when-things-go-wrong
      </a>
      [Last accessed: August 2022]
    </span>
    <hr />
  </div>
</template>

<script>
export default {
  name: 'ModuleOneQuizQ3',
  data() {
    return {
      a3: false,
      b3: false,
      c3: false,
      d3: false,
    };
  },
  watch: {
    a3(value) { this.$store.commit('answerM1A3', value) },
    b3(value) { this.$store.commit('answerM1B3', value) },
    c3(value) { this.$store.commit('answerM1C3', value) },
    d3(value) { this.$store.commit('answerM1D3', value) },
  },
};
</script>
