<template>
  <div>
    <span class="question">
      2. How should you time your apology to a patient?<sup>1</sup>
    </span>
    <span class="guidance">Select all that apply</span>
    <span class="anwser">
      <label>
        <input v-model="a2" type="checkbox">
        Once visiting hours are over and the ward is quiet
      </label>
    </span>
    <span class="anwser">
      <label>
        <input v-model="b2" type="checkbox">
        As soon as possible after something has gone wrong
      </label>
    </span>
    <span class="anwser">
      <label>
        <input v-model="c2" type="checkbox">
        When your Clinical Director is available
      </label>
    </span>
    <span class="anwser">
      <label>
        <input  v-model="d2" type="checkbox">
        When enough time has passed to determine the long-term effects of the incident
      </label>
    </span>
    <span class="anwser">
      <label>
        <input  v-model="e2" type="checkbox">
        When someone is available to support them
      </label>
    </span>
    <span class="notes">
      1. NHS Resolution. Saying Sorry. Available from: 
      <a href="https://resolution.nhs.uk/wp-content/uploads/2018/09/NHS-Resolution-Saying-Sorry.pdf">
        https://resolution.nhs.uk/wp-content/uploads/2018/09/NHS-Resolution-Saying-Sorry.pdf
      </a>
      [Last accessed: August 2022]
    </span>
    <hr />
  </div>
</template>

<script>
export default {
  name: 'ModuleOneQuizQ2',
  data() {
    return {
      a2: false,
      b2: false,
      c2: false,
      d2: false,
      e2: false,
    };
  },
  watch: {
    a2(value) { this.$store.commit('answerM1A2', value) },
    b2(value) { this.$store.commit('answerM1B2', value) },
    c2(value) { this.$store.commit('answerM1C2', value) },
    d2(value) { this.$store.commit('answerM1D2', value) },
    e2(value) { this.$store.commit('answerM1E2', value) },
  },
};
</script>
