<template>
  <div class="content pad-bottom--xl">
    <div
      v-if="this.$store.state.moduleOnePassed"
      class="grid-container pad-top">
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 pad-top">
          <h1 class="text-white large-heading pad-bottom">When things go wrong: A Clinician's duty towards the patient and those close to them</h1>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 pad bg--white pad-bottom--vxl">
          <p>You have already passed this module.</p>
          <p>
            <router-link to="/module-two">
              <button class="button">
                Go to Module Two
              </button>
            </router-link>
          </p>
        </div>
      </div>
    </div>
    <div v-else>
      <div
        v-if="!blocked"
        class="grid-container pad-top--xxl">
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 pad-top">
            <h1 class="text-white large-heading pad-bottom">What happens when things go wrong?</h1>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 pad bg--white">
            <Question1 />
            <Question2 />
            <Question3 />
            <Question4 />
            <Question5 />
            <Question6 />
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 pad bg--white">
            <button @click="markQuiz" class="button">Mark Quiz</button>
          </div>
        </div>
        <div
          v-if="score !== false"
          class="grid-x grid-padding-x">
          <div class="cell small-12 pad bg--white pad-bottom--vxl">
            <div v-if="score > 61">
              <p>You have passed the quiz with a score of {{ score }}%</p>
              <p>
                <router-link to="/module-two">
                  <button class="button">Go to Module Two</button>
                </router-link>
              </p>
            </div>
            <div v-else>
              <p>You failed the quiz with a score of {{ score }}%</p>
              <p>
                <router-link to="/module-one">
                  <button class="button">Re-read Module One</button>
                </router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="grid-container pad-top--xxl">
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 pad-top">
            <h1 class="text-white large-heading pad-bottom">What happens when things go wrong?</h1>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 pad bg--white pad-bottom--vxl">
            <p>You have already had two unsuccessful attempts at this quiz.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Question1 from '@/components/ModuleOne/Questions/Question1.vue';
import Question2 from '@/components/ModuleOne/Questions/Question2.vue';
import Question3 from '@/components/ModuleOne/Questions/Question3.vue';
import Question4 from '@/components/ModuleOne/Questions/Question4.vue';
import Question5 from '@/components/ModuleOne/Questions/Question5.vue';
import Question6 from '@/components/ModuleOne/Questions/Question6.vue';
import axios from '../axios';

export default {
  name: 'ModuleOneQuiz',
  components: {
    Question1,
    Question2,
    Question3,
    Question4,
    Question5,
    Question6,
  },
  data() {
    return {
      score: false,
    };
  },
  computed: {
    blocked() {
      let blocked = false;
      if (this.score === false) {
        if (this.$store.state.moduleOneAttempts > 1) {
          blocked = true;
        }
      } else {
        if (this.score < 70 && this.$store.state.moduleOneAttempts > 1) {
          blocked = true;
        }
      }
      return blocked;
    }
  },
  methods: {
    markQuiz() {
      const postData = {};
      postData.user = this.$store.state.userid;
      postData.module = 1;
      postData.answers = this.$store.state.moduleOneAnswers;
      console.log(postData);
      axios.post(`/attempts/create.json?token=${this.$store.state.token}`, postData)
        .then((response) => {
          this.score = response.data.score;
          this.$store.commit('moduleOneAttempts', response.data.attempts);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
