<template>
  <div>
    <span class="question">
      6. Which of these best represents the guidance on near misses?<sup>1</sup>
    </span>
    <span class="anwser">
      <label>
        <input v-model="a6" type="checkbox">
        All near misses should be discussed with the patient to avoid damaging their trust and confidence in you and the healthcare team
      </label>
    </span>
    <span class="anwser">
      <label>
        <input v-model="b6" type="checkbox">
        Telling patients about near misses is not advised as it does not affect their recovery
      </label>
    </span>
    <span class="anwser">
      <label>
        <input v-model="c6" type="checkbox">
        Professional judgement of a senior clinician can be used to help decide whether to talk to a patient about a near miss
      </label>
    </span>
    <span class="notes">
      1. General Medical Council. Openness and honesty when things go wrong. Available from:
      <a href="https://www.gmc-uk.org/ethical-guidance/ethical-guidance-for-doctors/candour---openness-and-honesty-when-things-go-wrong">
        https://www.gmc-uk.org/ethical-guidance/ethical-guidance-for-doctors/candour---openness-and-honesty-when-things-go-wrong
      </a>
      [Last accessed: August 2022].
    </span>
    <hr />
  </div>
</template>

<script>
export default {
  name: 'ModuleOneQuizQ6',
  data() {
    return {
      a6: false,
      b6: false,
      c6: false,
    };
  },
  watch: {
    a6(value) { this.$store.commit('answerM1A6', value) },
    b6(value) { this.$store.commit('answerM1B6', value) },
    c6(value) { this.$store.commit('answerM1C6', value) },
  },
};
</script>
