<template>
  <div>
    <span class="question">
      5. Which of these are additional duties of a senior or high-profile clinician?<sup>1,2</sup>
    </span>
    <span class="guidance">Select all that apply</span>
    <span class="anwser">
      <label>
        <input v-model="a5" type="checkbox">
        Actively foster a culture of learning and improvement
      </label>
    </span>
    <span class="anwser">
      <label>
        <input v-model="b5" type="checkbox">
        Take part in regular reviews or audits of the standards and performance of your team, taking steps to resolve any problems
      </label>
    </span>
    <span class="anwser">
      <label>
        <input v-model="c5" type="checkbox">
        Discourage individuals to report incidents that may suggest fitness-to-practice concerns
      </label>
    </span>
    <span class="notes">
      1. General Medical Council. Openness and honesty when things go wrong. Available from:
      <a href="https://www.gmc-uk.org/ethical-guidance/ethical-guidance-for-doctors/candour---openness-and-honesty-when-things-go-wrong">
        https://www.gmc-uk.org/ethical-guidance/ethical-guidance-for-doctors/candour---openness-and-honesty-when-things-go-wrong
      </a>
      [Last accessed: August 2022];
      2. General Medical Council. Leadership and management for all doctors (2012). Available from:
      <a href="chttps://www.gmc-uk.org/-/media/documents/leadership-and-management-for-all-doctors---english-1015_pdf-48903400.pdf">
        https://www.gmc-uk.org/-/media/documents/leadership-and-management-for-all-doctors---english-1015_pdf-48903400.pdf
      </a>
      [Last accessed: August 2022].
    </span>
    <hr />
  </div>
</template>

<script>
export default {
  name: 'ModuleOneQuizQ5',
  data() {
    return {
      a5: false,
      b5: false,
      c5: false,
    };
  },
  watch: {
    a5(value) { this.$store.commit('answerM1A5', value) },
    b5(value) { this.$store.commit('answerM1B5', value) },
    c5(value) { this.$store.commit('answerM1C5', value) },
  },
};
</script>
